import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { MQ } from '../../assets/styles/constants'

const ProductIntro = props => {
  const { title, image } = props

  if (image === null) {
    return null
  }

  const imagePath =
    image.localFile !== null ? image.localFile.childImageSharp.fluid : image.source_url

  const imageIsAvailableLocal = image.localFile !== null

  return (
    <ProductIntroWrapper>
      <ImageBackground>
        {imageIsAvailableLocal ? (
          <Img
            fluid={imagePath}
            critical
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            alt={title}
          />
        ) : (
          <img
            src={imagePath}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            alt={title}
          />
        )}
      </ImageBackground>
      <Content>
        <Headline dangerouslySetInnerHTML={{ __html: title }} />
      </Content>
    </ProductIntroWrapper>
  )
}

export default ProductIntro

const ProductIntroWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const ImageBackground = styled.div`
  user-select: none;
  position: absolute;
  height: 100vh;
  width: 100vw;
  max-width: 80%;
  z-index: 1;

  @media screen and (${MQ.lg}) {
    max-width: 1000px;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 80%;

  @media screen and (${MQ.lg}) {
    max-width: 1300px;
  }
`

const Headline = styled.span`
  display: block;
  font-size: 80px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: 30px;
  line-height: 1;

  @media screen and (${MQ.lg}) {
    font-size: 130px;
  }
`
