import React from 'react'
import styled from 'styled-components'

import { cubicBezier, MQ } from '../../assets/styles/constants'

class ProductVideo extends React.Component {
  constructor(props) {
    super(props)

    this.videoRef = React.createRef()
  }

  state = {
    videoIsPlaying: false,
    videoHasBeenInteractedWith: false,
  }

  handleOnClickPlayButton = () => {
    const { toggleModal, toggleScrolling } = this.props

    if (this.state.videoIsPlaying) {
      this.videoRef.current.pause()

      this.setState({
        videoIsPlaying: false,
        videoHasBeenInteractedWith: true,
      })

      toggleModal(false)
      toggleScrolling(true)
    } else {
      this.videoRef.current.play()

      if (!this.state.videoHasBeenInteractedWith) {
        this.videoRef.current.muted = false
        this.videoRef.current.currentTime = 0
        this.videoRef.current.loop = false
      }

      this.setState({ videoIsPlaying: true })

      toggleModal(true)
      toggleScrolling(false)
    }
  }

  componentDidUpdate() {
    const { toggleModal, toggleScrolling } = this.props

    if (this.state.videoIsPlaying) {
      this.videoRef.current.onended = () => {
        this.videoRef.current.muted = true
        this.videoRef.current.loop = false
        this.setState({ videoIsPlaying: false })

        toggleModal(false)
        toggleScrolling(true)
      }
    }
  }

  render() {
    const { videoIsPlaying } = this.state
    const { video } = this.props

    if (video === null) {
      return null
    }
    return (
      <ProductVideoWrapper>
        <ProductVideoInner videoIsPlaying={videoIsPlaying}>
          <video preload="auto" data-keepplaying autoPlay muted loop ref={this.videoRef}>
            <source src={video.localFile.publicURL} type="video/mp4" />
          </video>
          <Content className="product-video-content">
            <PlayButton className="product-video-playbutton" onClick={this.handleOnClickPlayButton}>
              <PlayIcon>
                <svg width="12px" height="11px" viewBox="0 0 12 11">
                  <path
                    d="M11.6220636,5.28063105 L1.23975127,0.0523628255 C1.11989407,-0.0090259396 1.01690504,-0.0160729927 0.930921118,0.0311472256 C0.844937195,0.0783426302 0.802,0.163353911 0.802,0.286131441 L0.802,10.7143308 C0.802,10.8372076 0.844964578,10.9222933 0.930921118,10.9695135 C1.01690504,11.0167089 1.11989407,11.0097611 1.23975127,10.9482979 L11.6220636,5.72000488 C11.7420303,5.65851686 11.802,5.58516795 11.802,5.50025593 C11.802,5.41524465 11.7420029,5.34204462 11.6220636,5.28063105 Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </PlayIcon>
              <span>Watch the film</span>
            </PlayButton>
            <CloseButton
              className="product-video-closebutton"
              onClick={this.handleOnClickPlayButton}
            />
          </Content>
        </ProductVideoInner>
      </ProductVideoWrapper>
    )
  }
}

export default ProductVideo

const ProductVideoWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProductVideoInner = styled.div`
  max-width: ${props => (props.videoIsPlaying ? '100vw' : '70vw')};
  width: ${props => (props.videoIsPlaying ? '100vw' : '70vw')};
  height: ${props => (props.videoIsPlaying ? '100%' : 'auto')};
  background: ${props => (props.videoIsPlaying ? '#000' : 'transparent')};
  transition: all 0.5s ${cubicBezier};
  position: relative;

  > video {
    display: block;
    height: 100%;
    width: 100%;
  }

  ${props =>
    props.videoIsPlaying &&
    `
      .product-video-content {
        background: rgba(0, 0, 0, 0);
        transition-delay: 0.5s;
      }
      .product-video-playbutton {
        bottom: -60vh;
        transition-delay: 0.5s;
      }
      .product-video-closebutton {
        top: 30px;
        transition: all 0.5s ${cubicBezier} 1s,
                    transform 0.5s ${cubicBezier},
                    opacity 0.5s ${cubicBezier};
      }
    `}
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ${cubicBezier};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`

const PlayButton = styled.div`
  display: flex;
  position: relative;
  bottom: 0;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s ${cubicBezier};
  transform: scale(1);
  opacity: 1;

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: scale(1.2);
    }
  }

  > span {
    font-size: 14px;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-left: 30px;
  }
`

const PlayIcon = styled.div`
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0px #fff inset, 0 0 1px 0px #fff;
  line-height: 60px;
  text-align: center;

  &:focus {
    outline: none;
  }
`

const CloseButton = styled.div`
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0px #fff inset, 0 0 1px 0px #fff;
  line-height: 60px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: -100px;
  right: 30px;
  transition: all 0.5s ${cubicBezier}, transform 0.5s ${cubicBezier}, opacity 0.5s ${cubicBezier};
  transform: scale(1);
  opacity: 1;

  &:before,
  &:after {
    content: '';
    background: #fff;
    height: 1px;
    width: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(135deg);
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`
