import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Logo } from '../assets/svgs/Logo'
import { cubicBezier, MQ } from '../assets/styles/constants'

class MainMenu extends React.Component {
  state = {
    openedMenu: false,
  }

  handleOnMouseOver = slug => {
    this.setState({ hoveredSlug: slug })
  }

  handleOnClick = () => {
    this.setState({ openedMenu: !this.state.openedMenu })
  }

  componentDidMount() {
    this.setState({ hoveredSlug: window.location.pathname.split('/')[2] })
  }

  render() {
    const { hoveredSlug, openedMenu } = this.state
    return (
      <StaticQuery
        query={graphql`
          query {
            categories: allWordpressPage(
              filter: { template: { eq: "page-category-frontpage.php" } }
              sort: { fields: menu_order, order: ASC }
            ) {
              edges {
                node {
                  slug
                  title
                  menu_order
                  acf {
                    background_image {
                      source_url
                      localFile {
                        childImageSharp {
                          fixed(width: 1920, quality: 100) {
                            ...GatsbyImageSharpFixed_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            <MainMenuButton onClick={this.handleOnClick}>
              <MainMenuButtonTrigger openedMenu={openedMenu}>
                <span className="bar-top" />
                <span className="bar-mid" />
                <span className="bar-bot" />
              </MainMenuButtonTrigger>
              <MainMenuButtonLabel openedMenu={openedMenu}>
                <span className="opened">Close</span>
                <span className="closed">Menu</span>
              </MainMenuButtonLabel>
            </MainMenuButton>
            <MainMenuWrapper openedMenu={openedMenu}>
              <MainMenuInner>
                <MainMenuItems>
                  <MainMenuItem
                    key={`mainmenuitem-collection`}
                    activeClassName="active"
                    to={`/collection`}
                  >
                    Collection
                  </MainMenuItem>
                  {data.categories.edges.map(category => (
                    <MainMenuItem
                      key={`mainmenuitem-${category.node.slug}`}
                      activeClassName="active"
                      to={`/collection/${category.node.slug}`}
                      onMouseOver={() => this.handleOnMouseOver(category.node.slug)}
                      dangerouslySetInnerHTML={{ __html: category.node.title }}
                    />
                  ))}
                </MainMenuItems>
                <CurrentMenuItemTeaser>
                  {data.categories.edges.map(category => {
                    if (category.node.acf.background_image === null) {
                      return null
                    }

                    const image =
                      category.node.acf.background_image.localFile !== null
                        ? category.node.acf.background_image.localFile.childImageSharp.fixed
                        : category.node.acf.background_image.source_url

                    const imageIsAvailableLocal =
                      category.node.acf.background_image.localFile !== null
                    if (imageIsAvailableLocal) {
                      return (
                        <Img
                          key={`menuitemteaser-${category.node.slug}`}
                          fixed={image}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            objetFit: 'cover',
                            width: '100%',
                            height: '100%',
                            opacity: hoveredSlug === category.node.slug ? 1 : 0,
                            transition: `all 0.5s ${cubicBezier}`,
                          }}
                          alt={category.node.title}
                        />
                      )
                    } else {
                      return (
                        <img
                          key={`menuitemteaser-${category.node.slug}`}
                          src={image}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            objetFit: 'cover',
                            width: '100%',
                            height: '100%',
                            opacity: hoveredSlug === category.node.slug ? 1 : 0,
                            transition: `all 0.5s ${cubicBezier}`,
                          }}
                          alt={category.node.title}
                        />
                      )
                    }
                  })}
                  <StyledLogo />
                </CurrentMenuItemTeaser>
              </MainMenuInner>
            </MainMenuWrapper>
          </React.Fragment>
        )}
      />
    )
  }
}

export default MainMenu

const MainMenuButton = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  position: fixed;
  z-index: 1100;
  top: 50px;
  left: 50px;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      span.bar-top {
        width: 20px;
      }

      span.bar-mid {
        width: 35px;
      }

      span.bar-bot {
        margin-left: 5px;
      }
    }
  }
`

const MainMenuButtonTrigger = styled.div`
  width: 35px;

  > span {
    display: block;
    height: 2px;
    margin-bottom: 10px;
    background: #fff;
    transition: all 0.5s ${cubicBezier};

    &.bar-top {
      width: ${props => (props.openedMenu ? '30px !important' : '30px')};
      transform: ${props => (props.openedMenu ? 'rotate(45deg)' : 'rotate(0deg)')};
      transform-origin: ${props => (props.openedMenu ? '10% 100%' : '0')};
    }

    &.bar-mid {
      width: 25px;
      opacity: ${props => (props.openedMenu ? 0 : 1)};
    }

    &.bar-bot {
      width: 30px;
      transform: ${props => (props.openedMenu ? 'rotate(-45deg)' : 'rotate(0deg)')};
      transform-origin: ${props => (props.openedMenu ? '-10% -100%' : '0')};
      margin-left: ${props => (props.openedMenu ? '0 !important' : 0)};
      margin-bottom: 0;
    }
  }
`

const MainMenuButtonLabel = styled.span`
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 20px;
  position: relative;
  height: 10px;

  > span {
    display: block;
    position: absolute;
    bottom: 0;
    transition: all 0.5s ${cubicBezier};

    &.opened {
      opacity: ${props => (props.openedMenu ? 1 : 0)};
      bottom: ${props => (props.openedMenu ? 0 : '-20px')};
    }
    &.closed {
      opacity: ${props => (props.openedMenu ? 0 : 1)};
      top: ${props => (props.openedMenu ? '-20px' : '-2px')};
    }
  }
`

const MainMenuWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1000;
  background: #000;
  pointer-events: ${props => (props.openedMenu ? 'auto' : 'none')};
  opacity: ${props => (props.openedMenu ? 1 : 0)};
  transition: all 0.5s ${cubicBezier};
`

const MainMenuInner = styled.div`
  display: flex;
`

const MainMenuItems = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
  padding-left: 15vw;
  user-select: none;
`

const MainMenuItem = styled(Link)`
  width: 200px;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 4px;
  text-transform: uppercase;
  transition: all 0.5s ${cubicBezier};

  @media screen and (${MQ.lg}) {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 30px;

    &:hover {
      padding-left: 15px;
    }
  }

  &.active {
    font-weight: bold;
  }
`

const CurrentMenuItemTeaser = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
  user-select: none;
`

const StyledLogo = styled(Logo)`
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 100;
`
