import React from 'react'
import styled from 'styled-components'
import ProductContentImages from './ProductContentImages'

import { cubicBezier, MQ } from '../../assets/styles/constants'

class ProductContent extends React.Component {
  constructor(props) {
    super(props)

    this.flickityRef = React.createRef()
  }

  render() {
    const {
      headline,
      text,
      expandedText,
      images,
      id,
      type,
      toggleModal,
      showModal,
      imagesFullHeight,
    } = this.props
    return (
      <ProductContentWrapper>
        <ProductContentInner type={type}>
          <ProductContentImages images={images} imagesFullHeight={imagesFullHeight} id={id} />
          <Content hasImages={images !== null && images.length > 0}>
            <ContentInner hasImages={images !== null && images.length > 0}>
              <Headline hasImages={images !== null && images.length > 0}>{headline}</Headline>
              <ContentText
                dangerouslySetInnerHTML={{ __html: text }}
                hasImages={images !== null && images.length > 0}
              />
              {expandedText !== '' && (
                <ExpandedTextTrigger showModal={showModal} onClick={() => toggleModal(true)} />
              )}
            </ContentInner>
          </Content>
        </ProductContentInner>
        {expandedText !== '' && (
          <ExpandedContentWrapper className="overlay" showModal={showModal}>
            <ExpandedContentText dangerouslySetInnerHTML={{ __html: expandedText }} />
            <ExpandedContentCloseButton onClick={() => toggleModal(false)} />
          </ExpandedContentWrapper>
        )}
      </ProductContentWrapper>
    )
  }
}

export default ProductContent

const ProductContentWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
`

const ProductContentInner = styled.div`
  display: flex;
  flex-direction: ${props => (props.type === 'Text right, image left' ? 'row' : 'row-reverse')};
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${props => (props.hasImages ? '50vw' : '100vw')};
  height: 100vh;
`

const ContentInner = styled.div`
  text-align: ${props => (props.hasImages ? 'left' : 'center')};
  max-width: 80%;
  @media screen and (${MQ.lg}) {
    max-width: ${props => (props.hasImages ? '600px' : '80%')};
  }
`

const Headline = styled.span`
  display: block;
  font-size: 40px;
  letter-spacing: 4px;
  text-transform: uppercase;
  max-width: ${props => (props.hasImages ? '90%' : '100%')};

  @media screen and (${MQ.lg}) {
    font-size: 50px;
  }
`

const ContentText = styled.div`
  max-width: ${props => (props.hasImages ? '90%' : '100%')};
`

const ExpandedContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  height: 100%;
  width: 100vw;
  padding: 60px 120px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  background: rgba(0, 0, 0, 0.95);
  opacity: ${props => (props.showModal ? 1 : 0)};
  transition: all 0.5s ${cubicBezier};

  pointer-events: ${props => (props.showModal ? 'auto' : 'none')};
  -webkit-backface-visibility: hidden;
  user-select: none;
`

const ExpandedContentText = styled.div`
  padding-bottom: 60px;
`

const ExpandedTextTrigger = styled.button`
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0px #fff inset, 0 0 1px 0px #fff;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  position: relative;
  transform: ${props => (props.showModal ? 'rotate(315deg)' : 'rotate(0deg)')};

  &:before,
  &:after {
    content: '';
    background: #fff;
    height: 1px;
    width: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
  }

  &:after {
    transform: rotate(90deg);
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: ${props => (props.showModal ? 'rotate(315deg)' : 'rotate(25deg)')};
    }
  }

  &:focus {
    outline: none;
  }
`

const ExpandedContentCloseButton = styled.div`
  margin: 0 auto;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0px #fff inset, 0 0 1px 0px #fff;
  line-height: 60px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ${cubicBezier}, transform 0.5s ${cubicBezier}, opacity 0.5s ${cubicBezier};
  transform: scale(1);

  &:before,
  &:after {
    content: '';
    background: #fff;
    height: 1px;
    width: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(135deg);
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`
