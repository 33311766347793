import React from 'react'
import styled from 'styled-components'

import { cubicBezier } from '../assets/styles/constants'

const ProgressMenu = props => {
  const itemsToLoop = []
  for (let index = 0; index < props.items; index++) {
    itemsToLoop.push(index)
  }

  return (
    <ProgressMenuWrapper>
      {itemsToLoop.map((item, index) => (
        <ProgressMenuItem
          selected={props.selected === index + 1}
          key={`ProgressMenuItem-${index}`}
        />
      ))}
    </ProgressMenuWrapper>
  )
}

export default ProgressMenu

const ProgressMenuWrapper = styled.div`
  position: fixed;
  right: 50px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 900;
`

const ProgressMenuItem = styled.div`
  float: right;
  clear: both;
  background: ${props => (props.selected ? '#fff' : 'transparent')};
  height: 10px;
  width: 10px;
  border: 1px solid #fff;
  border-radius: 100%;
  opacity: ${props => (props.selected ? '1' : '0.3')};
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  margin-bottom: 12px;
`
