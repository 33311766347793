import React from 'react'
import styled from 'styled-components'
import ProductFullscreenImages from './ProductFullscreenImages'

const ProductFullscreen = props => {
  const { video, images, text, id } = props
  return (
    <ProductFullscreenWrapper>
      {video && video.localFile.publicURL ? (
        <VideoBackground>
          <video data-keepplaying autoPlay muted loop preload="auto">
            <source src={video && video.localFile.publicURL} type="video/mp4" />
          </video>
        </VideoBackground>
      ) : (
        <ProductFullscreenImages images={images} id={id} />
      )}
      {text.length > 0 && (
        <Content>
          <ContentText dangerouslySetInnerHTML={{ __html: text }} />
        </Content>
      )}
    </ProductFullscreenWrapper>
  )
}

export default ProductFullscreen

const VideoBackground = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  > video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
`

const ProductFullscreenWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Content = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 15%;
  left: 10%;
  width: 40%;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 24px;
`

const ContentText = styled.div``
