import React from 'react'
import { graphql } from 'gatsby'
import ReactFullpage from '@fullpage/react-fullpage'

import MainMenu from '../components/MainMenu'
import RestrictionsMessage from '../components/RestrictionsMessage'
import ProgressMenu from '../components/ProgressMenu'
import SectionMenu from '../components/SectionMenu/'
import ScrollIndicator from '../components/ScrollIndicator'

import ProductIntro from '../components/ProductSections/ProductIntro'
import ProductContent from '../components/ProductSections/ProductContent'
import ProductWearing from '../components/ProductSections/ProductWearing'
import ProductVideo from '../components/ProductSections/ProductVideo'
import ProductFullscreen from '../components/ProductSections/ProductFullscreen'

class PageCategoryProduct extends React.Component {
  constructor(props) {
    super(props)

    this.reactFullPageRef = React.createRef()
  }
  state = {
    activePage:
      this.props.location.state && this.props.location.state.clickedSectionId
        ? this.props.location.state.clickedSectionId
        : 1,
    showScrollIndicator: true,
    showModal: false,
  }

  handleReactFullPageAfterLoad = ({ origin, destination, direction }) => {
    this.setState({
      activePage: destination.index + 1,
      showScrollIndicator: false,
    })
  }

  toggleScrolling = toggle => {
    this.reactFullPageRef.current.fullpageApi.setAllowScrolling(toggle)
  }

  toggleModal = toggle => {
    console.log(toggle)
    this.setState({ showModal: toggle })
  }

  handleOnSectionClick = clickedSection => {
    this.reactFullPageRef.current.fullpageApi.moveTo(clickedSection)
  }

  render() {
    const product = this.props.data.product

    const lengthOfSections = product.acf.sections_page ? product.acf.sections_page.length : 0

    const products = this.props.data.products.edges.filter(
      productsElement => productsElement.node.parent_element.id === product.parent_element.id,
    )

    return (
      <React.Fragment>
        <RestrictionsMessage />
        <MainMenu />
        <ProgressMenu selected={this.state.activePage} items={lengthOfSections} />
        <SectionMenu
          selectedSection={this.state.activePage}
          currentPageId={product.id}
          products={products}
          onSectionClick={this.handleOnSectionClick}
        />
        {this.state.showModal && <style>{`.fullpage-wrapper {z-index: 1100;}`}</style>}
        <ReactFullpage
          licenseKey="78B68DD0-5E89429F-83882F6A-5CF1BF97"
          onLeave={(origin, destination, direction) => {
            this.handleReactFullPageAfterLoad({ origin, destination, direction })
          }}
          ref={this.reactFullPageRef}
          normalScrollElements=".overlay"
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                {product.acf.sections_page &&
                  product.acf.sections_page.map((section, index) => {
                    switch (section.__typename) {
                      case 'WordPressAcf_intro':
                        return (
                          <div className="section" key={`product-page-section-${index}`}>
                            <ProductIntro title={product.title} image={section.background_image} />
                          </div>
                        )
                      case 'WordPressAcf_content':
                        return (
                          <div className="section" key={`product-page-section-${index}`}>
                            <ProductContent
                              id={section.id}
                              headline={section.headline}
                              text={section.text}
                              type={section.type}
                              expandedText={section.expanded_text}
                              images={section.images}
                              imagesFullHeight={section.images_centered_or_full_height}
                              showModal={this.state.showModal}
                              toggleModal={this.toggleModal}
                            />
                          </div>
                        )
                      case 'WordPressAcf_wearing':
                        return (
                          <div className="section" key={`product-page-section-${index}`}>
                            <ProductWearing id={section.id} images={section.images} />
                          </div>
                        )
                      case 'WordPressAcf_video':
                        return (
                          <div className="section" key={`product-page-section-${index}`}>
                            <ProductVideo
                              video={section.video_background}
                              toggleModal={this.toggleModal}
                              toggleScrolling={this.toggleScrolling}
                            />
                          </div>
                        )
                      case 'WordPressAcf_fullscreen':
                        return (
                          <div className="section" key={`product-page-section-${index}`}>
                            <ProductFullscreen
                              text={section.text}
                              video={section.background_video}
                              images={section.background_images}
                            />
                          </div>
                        )
                      default:
                        return ''
                    }
                  })}
              </ReactFullpage.Wrapper>
            )
          }}
        />
        <ScrollIndicator label="" showScrollIndicator={this.state.showScrollIndicator} />
      </React.Fragment>
    )
  }
}

export default PageCategoryProduct

export const pageQuery = graphql`
  query($id: String!) {
    products: allWordpressPage(
      filter: { template: { eq: "page-category-product.php" } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          slug
          title
          id
          parent_element {
            id
            slug
          }
          acf {
            sections_page {
              __typename
              ... on WordPressAcf_intro {
                section_title
                id
              }
              ... on WordPressAcf_content {
                section_title
                id
              }
              ... on WordPressAcf_wearing {
                section_title
                id
              }
              ... on WordPressAcf_video {
                section_title
                id
              }
              ... on WordPressAcf_fullscreen {
                section_title
                id
              }
            }
          }
        }
      }
    }
    product: wordpressPage(id: { eq: $id }) {
      title
      id
      slug
      parent_element {
        id
      }
      acf {
        sections_page {
          __typename
          ... on WordPressAcf_intro {
            id
            background_image {
              source_url
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_content {
            id
            headline
            text
            expanded_text
            type
            images {
              source_url
              localFile {
                childImageSharp {
                  fixed(width: 1920, quality: 100) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            images_centered_or_full_height
          }
          ... on WordPressAcf_wearing {
            id
            images {
              headline
              text
              image {
                source_url
                localFile {
                  childImageSharp {
                    fixed(width: 1920, quality: 100) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_video {
            id
            video_background {
              source_url
              localFile {
                publicURL
              }
            }
          }
          ... on WordPressAcf_fullscreen {
            id
            background_video {
              source_url
              localFile {
                publicURL
              }
            }
            background_images {
              source_url
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            text
          }
        }
      }
    }
  }
`
