/**
 * Button
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './Button.module.scss';

const Button = ({ theme, className, children, ...rest }) => (
  <button className={classnames(css.root, css[theme], className)} {...rest}>
    {children}
  </button>
);

Button.propTypes = {
  theme: PropTypes.oneOf(['dark']),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Button;
